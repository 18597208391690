const InfoIconSVG: React.FC<{ width?: number; height?: number }> = ({ width = 12, height = 12 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.83337 3.56012C6.83337 4.02043 6.46024 4.39309 5.99994 4.39309C5.53963 4.39309 5.1665 4.02043 5.1665 3.56012C5.1665 3.09981 5.53963 2.72668 5.99994 2.72668C6.46024 2.72668 6.83337 3.09981 6.83337 3.56012Z"
        fill="inherit"
      />
      <path
        d="M6.69319 5.84293V8.58985C6.69319 8.97282 6.38288 9.28313 5.99992 9.28313C5.61695 9.28313 5.30664 8.97282 5.30664 8.58985V5.84293C5.30664 5.46043 5.61695 5.14966 5.99992 5.14966C6.38288 5.14966 6.69319 5.46043 6.69319 5.84293Z"
        fill="inherit"
      />
      <path
        d="M6 0C2.68644 0 0 2.68644 0 6C0 9.31356 2.68644 12 6 12C9.31356 12 12 9.31356 12 6C12 2.68644 9.31356 0 6 0ZM9.4884 9.4884C8.5659 10.4264 7.3068 10.9574 5.991 10.9631C4.67568 10.9687 3.41196 10.4484 2.48196 9.51792C1.55149 8.58792 1.03116 7.3242 1.0368 6.00888C1.04243 4.69308 1.57352 3.43404 2.51148 2.51148C3.43398 1.57351 4.69308 1.04244 6.00888 1.0368C7.3242 1.03118 8.58792 1.55149 9.51792 2.48196C10.4484 3.41196 10.9687 4.67568 10.9631 5.991C10.9575 7.3068 10.4264 8.56584 9.4884 9.4884Z"
        fill="inherit"
      />
      <path
        d="M6 0C2.68644 0 0 2.68644 0 6C0 9.31356 2.68644 12 6 12C9.31356 12 12 9.31356 12 6C12 2.68644 9.31356 0 6 0ZM9.4884 9.4884C8.5659 10.4264 7.3068 10.9574 5.991 10.9631C4.67568 10.9687 3.41196 10.4484 2.48196 9.51792C1.55149 8.58792 1.03116 7.3242 1.0368 6.00888C1.04243 4.69308 1.57352 3.43404 2.51148 2.51148C3.43398 1.57351 4.69308 1.04244 6.00888 1.0368C7.3242 1.03118 8.58792 1.55149 9.51792 2.48196C10.4484 3.41196 10.9687 4.67568 10.9631 5.991C10.9575 7.3068 10.4264 8.56584 9.4884 9.4884Z"
        fill="inherit"
      />
    </svg>
  );
};

export default InfoIconSVG;
